import { Project } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function ProjectInfoTab({ project }: { project: Project }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell style={{ width: '100%' }}>{project.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Stage</TableCell>
          <TableCell style={{ width: '100%' }}>{project.stage}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Description</TableCell>
          <TableCell style={{ width: '100%' }}>{project.description}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Start Date</TableCell>
          <TableCell style={{ width: '100%' }}>{project.startDate?.toString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Completion Date</TableCell>
          <TableCell style={{ width: '100%' }}>{project.completionDate?.toString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Warranty Date</TableCell>
          <TableCell style={{ width: '100%' }}>{project.warrantyDate?.toString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Address</TableCell>
          <TableCell style={{ width: '100%' }}>{project.address}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Project Manager User Id</TableCell>
          <TableCell style={{ width: '100%' }}>{project.projectManagerUserId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Designer User Id</TableCell>
          <TableCell style={{ width: '100%' }}>{project.designerUserId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Design Status</TableCell>
          <TableCell style={{ width: '100%' }}>{project.designStatus}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Installation Status</TableCell>
          <TableCell style={{ width: '100%' }}>{project.installationStatus}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Is Archived</TableCell>
          <TableCell style={{ width: '100%' }}>{project.isArchived}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Is Published</TableCell>
          <TableCell style={{ width: '100%' }}>{project.isPublished}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Customer Project Id</TableCell>
          <TableCell style={{ width: '100%' }}>{project.customProjectId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Published By User Id</TableCell>
          <TableCell style={{ width: '100%' }}>{project.publishedByUserId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Published Date</TableCell>
          <TableCell style={{ width: '100%' }}>{project.publishedDate?.toString()}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
