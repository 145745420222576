import { Org } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function OrgInfoTab({ org }: { org: Org }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell style={{ width: '100%' }}>{org.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Email</TableCell>
          <TableCell style={{ width: '100%' }}>{org.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Phone</TableCell>
          <TableCell style={{ width: '100%' }}>{org.phoneNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Is Active</TableCell>
          <TableCell style={{ width: '100%' }}>{org.isActive}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>License Type</TableCell>
          <TableCell style={{ width: '100%' }}>{org.licenseType}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
