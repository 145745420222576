import { FavoriteDevice } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function FavoriteDeviceInfoTab({ favoriteDevice }: Readonly<{ favoriteDevice: FavoriteDevice }>) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell style={{ width: '100%' }}>{favoriteDevice.name}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
