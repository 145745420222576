import { useRouteMatch } from 'react-router-dom'
import LocationInfoTab from './LocationInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useLocation } from '@/lib/db'
import { LocationRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends LocationRef {
  tabId: string | undefined
}

export default function LocationPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [location, loading] = useLocation({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!location) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">
            {location.imageLeft} x {location.imageTop}
          </Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <LocationInfoTab location={location} />
        </TabPanel>
      </Paper>
    </>
  )
}
