import { useRouteMatch } from 'react-router-dom'
import ProjectFloorsTab from './ProjectFloorsTab'
import ProjectMembersTab from './ProjectMembersTab'
import ProjectInfoTab from './ProjectInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useProject } from '@/lib/db'
import { ProjectRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends ProjectRef {
  tabId: string | undefined
}

export default function ProjectPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [project, loading] = useProject({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!project) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{project.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Floors" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <ProjectInfoTab project={project} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProjectFloorsTab project={project} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ProjectMembersTab project={project} />
        </TabPanel>
      </Paper>
    </>
  )
}
