import { useRouteMatch } from 'react-router-dom'
import FavoriteTaskInfoTab from './FavoriteTaskInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFavoriteTask } from '@/lib/db'
import { FavoriteTaskRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends FavoriteTaskRef {
  tabId: string | undefined
}

export default function FavoriteTaskPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [favoriteTask, loading] = useFavoriteTask({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!favoriteTask) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{favoriteTask.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FavoriteTaskInfoTab favoriteTask={favoriteTask} />
        </TabPanel>
      </Paper>
    </>
  )
}
