import { useRouteMatch } from 'react-router-dom'
import ProjectFloorInfoTab from './ProjectFloorInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useProjectFloor } from '@/lib/db'
import { ProjectFloorRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends ProjectFloorRef {
  tabId: string | undefined
}

export default function ProjectFloorPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [projectFloor, loading] = useProjectFloor({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!projectFloor) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{projectFloor.floorId}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <ProjectFloorInfoTab projectFloor={projectFloor} />
        </TabPanel>
      </Paper>
    </>
  )
}
