import { Link, useLocation } from 'react-router-dom'
import { useServiceTickets } from '@/lib/db'
import { Customer, ServiceTicket } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function CustomerServiceTicketsTab({ customer }: Readonly<{ customer: Customer }>) {
  const [serviceTickets, loading] = useServiceTickets(customer)
  const location = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Service Ticket</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceTickets?.map((serviceTicket: ServiceTicket) => {
            return (
              <TableRow key={serviceTicket.id}>
                <TableCell>
                  <Link to={`${location.pathname}/service-tickets/${serviceTicket.id}`}>{serviceTicket.subject}</Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
