import { Link, useLocation } from 'react-router-dom'
import { useLocations } from '@/lib/db'
import { Floor } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function FloorDevicesTab({ floor }: Readonly<{ floor: Floor }>) {
  const [locations, loading] = useLocations(floor)
  const routerLocation = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Device</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations?.map((location) => {
            return (
              <TableRow key={location.id}>
                <TableCell>
                  <Link to={`${routerLocation.pathname}/locations/${location.id}`}>
                    {location.name || location.imageLeft + '/' + location.imageTop}
                  </Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
