import { useUser } from '@/components/auth/UserProvider'
import { useUserFloorPreference } from '@/lib/db'
import { Floor } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function FloorPreferencesTab({ floor }: Readonly<{ floor: Floor }>) {
  const user = useUser()
  const [userFloorPreferences, loading] = useUserFloorPreference({ userId: user.id, floorId: floor.id })

  if (loading || !userFloorPreferences) return <></>

  return (
    <Table sx={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>userId</TableCell>
          <TableCell sx={{ width: '100%' }}>{userFloorPreferences.userId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>showDeviceSize</TableCell>
          <TableCell sx={{ width: '100%' }}>{userFloorPreferences.showDeviceSize}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>hideLiveDevice</TableCell>
          <TableCell sx={{ width: '100%' }}>{userFloorPreferences.hideLiveDevice}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>pinColorPreference</TableCell>
          <TableCell sx={{ width: '100%' }}>{userFloorPreferences.pinColorPreference}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
