import { Location } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

export default function LocationInfoTab({ location }: { location: Location }) {
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
              <TableCell style={{ width: '100%' }}>{location.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Image Left</TableCell>
              <TableCell style={{ width: '100%' }}>{location.imageLeft}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Image Top</TableCell>
              <TableCell style={{ width: '100%' }}>{location.imageTop}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
