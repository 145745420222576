import { Link, useLocation } from 'react-router-dom'
import { useBuildings } from '@/lib/db'
import { Site } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function SiteBuildingsTab({ site }: Readonly<{ site: Site }>) {
  const [buildings, loading] = useBuildings(site)
  const location = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Building</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buildings?.map((building) => {
            return (
              <TableRow key={building.id}>
                <TableCell>
                  <Link to={`${location.pathname}/buildings/${building.id}`}>{building.name}</Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
