import { Floor } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function FloorInfoTab({ floor }: { floor: Floor }) {
  return (
    <>
      <Table sx={{ border: '1px solid', borderRadius: 'lg', borderSpacing: 0 }}>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                borderRadius: 'lg',
                border: '1px solid',
                textAlign: 'right',
                backgroundColor: 'muted',
                p: 3,
                whiteSpace: 'nowrap',
              }}
            >
              Name
            </TableCell>
            <TableCell sx={{ borderRadius: 'lg', border: '1px solid', p: 3, width: '100%' }}>{floor.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ border: '1px solid', textAlign: 'right', backgroundColor: 'muted', p: 3, whiteSpace: 'nowrap' }}
            >
              Plan Image File Name
            </TableCell>
            <TableCell sx={{ border: '1px solid', p: 3, width: '100%' }}>{floor.planImageFileName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ border: '1px solid', textAlign: 'right', backgroundColor: 'muted', p: 3, whiteSpace: 'nowrap' }}
            >
              Plan Image Path
            </TableCell>
            <TableCell sx={{ border: '1px solid', p: 3, width: '100%' }}>{floor.planImagePath}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                borderRadius: 'lg',
                border: '1px solid',
                textAlign: 'right',
                backgroundColor: 'muted',
                p: 3,
                whiteSpace: 'nowrap',
              }}
            >
              Order Number
            </TableCell>
            <TableCell sx={{ borderRadius: 'lg', border: '1px solid', p: 3, width: '100%' }}>
              {floor.orderNumber}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                borderRadius: 'lg',
                border: '1px solid',
                textAlign: 'right',
                backgroundColor: 'muted',
                p: 3,
                whiteSpace: 'nowrap',
              }}
            >
              Width
            </TableCell>
            <TableCell sx={{ borderRadius: 'lg', border: '1px solid', p: 3, width: '100%' }}>{floor.width}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                borderRadius: 'lg',
                border: '1px solid',
                textAlign: 'right',
                backgroundColor: 'muted',
                p: 3,
                whiteSpace: 'nowrap',
              }}
            >
              Height
            </TableCell>
            <TableCell sx={{ borderRadius: 'lg', border: '1px solid', p: 3, width: '100%' }}>{floor.height}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                borderRadius: 'lg',
                border: '1px solid',
                textAlign: 'right',
                backgroundColor: 'muted',
                p: 3,
                whiteSpace: 'nowrap',
              }}
            >
              Is Archived
            </TableCell>
            <TableCell sx={{ borderRadius: 'lg', border: '1px solid', p: 3, width: '100%' }}>
              {floor.isArchived}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                borderRadius: 'lg',
                border: '1px solid',
                textAlign: 'right',
                backgroundColor: 'muted',
                p: 3,
                whiteSpace: 'nowrap',
              }}
            >
              Floor Scale
            </TableCell>
            <TableCell sx={{ borderRadius: 'lg', border: '1px solid', p: 3, width: '100%' }}>
              {floor.floorScale}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {floor.planImagePath && (
        <img src={`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`} alt={floor.name} />
      )}
    </>
  )
}
