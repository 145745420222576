import { Link, useLocation } from 'react-router-dom'
import { useFloors } from '@/lib/db'
import { Building, Floor } from '@/lib/types'
import { useFirestore } from '../FirebaseProvider'
import { loadBundle } from 'firebase/firestore'
import { useState } from 'react'
import { Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function BuildingFloorTab({ building }: Readonly<{ building: Building }>) {
  const [floors, loading] = useFloors(building)

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Floors</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {floors?.map((floor) => {
            return <FloorRow key={floor.id} floor={floor} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function FloorRow({ floor }: { floor: Floor }) {
  const firestore = useFirestore()
  const [isChecked, setIsChecked] = useState(localStorage.getItem(`floor-${floor.id}`) === 'true')
  const location = useLocation()

  function handleCheckboxChange() {
    const newCheckedState = !isChecked
    setIsChecked(newCheckedState)
    localStorage.setItem(`floor-${floor.id}`, newCheckedState.toString())
    if (newCheckedState) {
      downloadFloorForOfflineUse(floor)

      // fetch and pre-cache the image url stored in floor.planImagePath
      if (floor.planImagePath) {
        fetch(`${import.meta.env.VITE_IMGIX_BASE_URL}/${floor.planImagePath}`)
      }
    }
  }

  async function downloadFloorForOfflineUse(floor: Floor) {
    console.log('Downloading floor for offline use:', floor.id)
    // call the get_floor_offline_bundle function
    const floorRef = {
      orgId: floor.orgId,
      customerId: floor.customerId,
      siteId: floor.siteId,
      buildingId: floor.buildingId,
      floorId: floor.id,
    }

    console.log('floorRef', floorRef)

    // use fetch to make a post call to an http endpoint that will return the bundle
    const response = await fetch(
      `${import.meta.env.VITE_CLOUD_FUNCTIONS_ROOT}/offline-default/orgs/${floor.orgId}/customers/${floor.customerId}/sites/${floor.siteId}/buildings/${floor.buildingId}/floors/${floor.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    console.log('response', response.status)

    // load the response into the local cache
    const bundle = await response.arrayBuffer()
    await loadBundle(firestore, bundle)

    console.log('bundle loaded')
  }

  return (
    <TableRow key={floor.id}>
      <TableCell>
        <Link to={`${location.pathname}/floors/${floor.id}`}>{floor.name}</Link>
      </TableCell>
      <TableCell>
        <Switch checked={isChecked} onChange={handleCheckboxChange} />
      </TableCell>
    </TableRow>
  )
}
