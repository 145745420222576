import { Customer } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function CustomerInfoTab({ customer }: { customer: Customer }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Name
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Email
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Phone
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.phoneNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Is Active
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.isActive}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Is Local Save Enabled
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.settings.isLocalSaveEnabled}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Is Cabling Enabled
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.settings.isCabling}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Is Live System Access Enabled
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.settings.isLiveSystemAccess}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Is Local Save Enabled
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.settings.isLocalSaveEnabled}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right" variant="head" style={{ whiteSpace: 'nowrap' }}>
            Unlimited Plan In Reports
          </TableCell>
          <TableCell style={{ width: '100%' }}>{customer.settings.isUnlimitedPlansInReports}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
