import { Device } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function DeviceInfoTab({ device }: { device: Device }) {
  return (
    <Table sx={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>ID</TableCell>
          <TableCell sx={{ width: '100%' }}>{device.id}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
