import { Task } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function TaskInfoTab({ task }: { task: Task }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell style={{ width: '100%' }}>{task.name}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
