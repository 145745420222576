import { useUser } from '@/components/auth/UserProvider'
import { useUserCustomerPreference } from '@/lib/db'
import { Customer } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function CustomerPreferencesTab({ customer }: Readonly<{ customer: Customer }>) {
  const user = useUser()
  const [userCustomerPreferences, loading] = useUserCustomerPreference({
    userId: user.id,
    customerId: customer.id,
  })

  if (loading || !userCustomerPreferences) return <></>

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>userId</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.userId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>amtDeviceField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.amtDeviceField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>projectDeviceField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.projectDeviceField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>serviceTicketField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.serviceTicketField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>projectDevicePunchListField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.projectDevicePunchListField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>isProjectDevicePunchList</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.isProjectDevicePunchList}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>amtDevicePhotoReviewField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.amtDevicePhotoReviewField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>amtFieldView</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.amtFieldView}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>projectFieldView</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.projectFieldView}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>projectDeviceCompletedWorkField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.projectDeviceCompletedWorkField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>projectDeviceFlagReviewField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.projectDeviceFlagReviewField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>projectReportField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.projectReportField}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>livesiteReportField</TableCell>
          <TableCell style={{ width: '100%' }}>{userCustomerPreferences.livesiteReportField}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
