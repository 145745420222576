import { Favorite } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function FavoriteInfoTab({ favorite }: { favorite: Favorite }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: '100%', whiteSpace: 'nowrap' }} align="right" variant="head">
            Name
          </TableCell>
          <TableCell sx={{ width: '100%' }}>{favorite.name}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
