import { ServiceTicket } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function ServiceTicketInfoTab({ serviceTicket }: { serviceTicket: ServiceTicket }) {
  return (
    <Table sx={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>subject</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.subject}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>requestedByUserId</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.requestedByUserId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>status</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>priority</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.priority}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>floorId</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.floorId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>description</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.description}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>assignedToUserId</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.assignedToUserId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>dueDate</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.dueDate?.toString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>closedDate</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.closedDate?.toString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>cost</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.cost}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>isArchived</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.isArchived}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>scheduledDate</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.scheduledDate?.toString()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>deviceIds</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.deviceIds}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>quote.preparedFor</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.quote?.preparedFor}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>quote.preparedBy</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.quote?.preparedBy}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>quote.taxPercentage</TableCell>
          <TableCell sx={{ width: '100%' }}>{serviceTicket.quote?.taxPercentage}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
