import { Site } from '@/lib/types'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'

export default function SiteInfoTab({ site }: { site: Site }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Address</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.address}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>City</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.city}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>State</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.state}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Zip Code</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.zip}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Notes</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.notes}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Is Archived</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.isArchived}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Default Ticket Assigned To</TableCell>
          <TableCell sx={{ width: '100%' }}>{site.defaultTicketAssignedToUserId}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
