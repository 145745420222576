import { useRouteMatch } from 'react-router-dom'
import FavoriteDeviceInfoTab from './FavoriteDeviceInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFavoriteDevice } from '@/lib/db'
import { FavoriteDeviceRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends FavoriteDeviceRef {
  tabId: string | undefined
}

export default function FavoriteDevicePage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [favoriteDevice, loading] = useFavoriteDevice({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!favoriteDevice) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{favoriteDevice.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FavoriteDeviceInfoTab favoriteDevice={favoriteDevice} />
        </TabPanel>
      </Paper>
    </>
  )
}
