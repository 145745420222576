import { Link, useLocation } from 'react-router-dom'
import { useProjectFloors } from '@/lib/db'
import { Project } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function ProjectFloorsTab({ project }: Readonly<{ project: Project }>) {
  const [projectFloors, loading] = useProjectFloors(project)
  const location = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Floor</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectFloors?.map((projectFloor) => {
            return (
              <TableRow key={projectFloor.floorId}>
                <TableCell>
                  <Link to={`${location.pathname}/floors/${projectFloor.floorId}`}>{projectFloor.floorId}</Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
