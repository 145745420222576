import { ProjectFloor } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function ProjectFloorInfoTab({ projectFloor }: { projectFloor: ProjectFloor }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Floor Id</TableCell>
          <TableCell style={{ width: '100%' }}>{projectFloor.floorId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Plan Image Name</TableCell>
          <TableCell style={{ width: '100%' }}>{projectFloor.planImageName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Plan Image Path</TableCell>
          <TableCell style={{ width: '100%' }}>{projectFloor.planImagePath}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
