import { Link, useLocation } from 'react-router-dom'
import { useCustomers } from '@/lib/db'
import { Org } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function OrgsCustomersTab({ org }: Readonly<{ org: Org }>) {
  const [customers, loading] = useCustomers(org)
  const location = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers?.map((customer) => {
            return (
              <TableRow key={customer.id}>
                <TableCell>
                  <Link to={`${location.pathname}/customers/${customer.id}`}>{customer.name}</Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
