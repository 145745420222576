import { useRouteMatch } from 'react-router-dom'
import CustomerSitesTab from './CustomerSitesTab'
import CustomerMembersTab from './CustomerMembersTab'
import CustomerInfoTab from './CustomerInfoTab'
import CustomerFavoritesTab from './CustomerFavoritesTab'
import CustomerPreferencesTab from './CustomerPreferencesTab'
import CustomerProjectsTab from './CustomerProjectsTab'
import CustomerServiceTicketsTab from './CustomerServiceTicketsTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useCustomer } from '@/lib/db'
import { CustomerRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useState } from 'react'
import TabPanel from '@/ui-component/TabPanel'

interface MatchParams extends CustomerRef {
  tabId: string | undefined
}

export default function CustomerPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [customer, loading] = useCustomer({ ...params, orgId: currentOrg.id })
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  if (loading) return <></>

  if (!customer) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{customer.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Sites" />
          <Tab label="Projects" />
          <Tab label="Service Tickets" />
          <Tab label="Favorites" />
          <Tab label="Preferences" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <CustomerInfoTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerSitesTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <CustomerProjectsTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <CustomerServiceTicketsTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <CustomerFavoritesTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <CustomerPreferencesTab customer={customer} />
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <CustomerMembersTab customer={customer} />
        </TabPanel>
      </Paper>
    </>
  )
}
