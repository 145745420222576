import { useRouteMatch } from 'react-router-dom'
import FloorLocationsTab from './FloorLocationsTab'
import FloorInfoTab from './FloorInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFloor } from '@/lib/db'
import FloorPreferencesTab from './FloorPreferencesTab'
import FloorMapTab from './FloorMapTab'
import FloorDevicesTab from './FloorDevicesTab'
import { FloorRef } from '@/lib/types'
import { XMarkIcon } from '@heroicons/react/24/outline'
import FloorTasksTab from './FloorTasksTab'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends FloorRef {
  tabId: string | undefined
}

export default function FloorPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [floor, loading] = useFloor({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!floor) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{floor.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Locations" />
          <Tab label="User Preferences" />
          <Tab label="Devices" />
          <Tab label="Tasks" />
          <Tab label="Map" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FloorInfoTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FloorLocationsTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FloorPreferencesTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <FloorDevicesTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <FloorTasksTab floor={floor} />
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <h1>Map</h1>
          {/* <dialog id="map_modal" className="modal h-screen ">
        <div className="modal-box h-screen relative max-w-none w-full p-0">
          <FloorMapTab floor={floor} />
          <button
            className="absolute top-0 right-0 p-4"
            onClick={() => {
              ;(document.getElementById('map_modal') as HTMLDialogElement)?.close()
            }}
          >
            <XMarkIcon className="h-6 w-6 text-gray-500" />
          </button>
        </div>
      </dialog> */}
        </TabPanel>
      </Paper>
    </>
  )
}
