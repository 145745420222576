import { Link, useLocation } from 'react-router-dom'
import { useSiteMembers } from '@/lib/db'
import { Site } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function SiteMembersTab({ site }: Readonly<{ site: Site }>) {
  const [members, loading] = useSiteMembers(site)
  const location = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Member</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.map((member) => {
            return (
              <TableRow key={member.userId}>
                <TableCell>
                  <Link to={`${location.pathname}/members/${member.userId}`}>{member.userId}</Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
