import { Building } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function BuildingInfoTab({ building }: { building: Building }) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell style={{ width: '100%' }}>{building.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Address</TableCell>
          <TableCell style={{ width: '100%' }}>{building.address}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Order Number</TableCell>
          <TableCell style={{ width: '100%' }}>{building.orderNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ whiteSpace: 'nowrap' }}>Is Archived</TableCell>
          <TableCell style={{ width: '100%' }}>{building.isArchived}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
