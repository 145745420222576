import { useRouteMatch } from 'react-router-dom'
import ServiceTicketWatchersTab from './ServiceTicketWatchersTab'
import ServiceTicketInfoTab from './ServiceTicketInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useServiceTicket } from '@/lib/db'
import ServiceTicketCommentsTab from './ServiceTicketCommentsTab'
import ServiceTicketFileAttachmentsTab from './ServiceTicketFilesTab'
import { ServiceTicketRef } from '@/lib/types'
import { useCurrentOrg } from '../auth/UserProvider'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends ServiceTicketRef {
  tabId: string | undefined
}

export default function ServiceTicketPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [serviceTicket, loading] = useServiceTicket({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!serviceTicket) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{serviceTicket.subject}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Watchers" />
          <Tab label="Comments" />
          <Tab label="Files" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <ServiceTicketInfoTab serviceTicket={serviceTicket} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ServiceTicketWatchersTab serviceTicket={serviceTicket} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ServiceTicketCommentsTab serviceTicket={serviceTicket} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ServiceTicketFileAttachmentsTab serviceTicket={serviceTicket} />
        </TabPanel>
      </Paper>
    </>
  )
}
