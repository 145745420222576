import { useRouteMatch } from 'react-router-dom'
import FavoriteInfoTab from './FavoriteInfoTab'
import FavoriteFavoriteDevicesTab from './FavoriteDevicesTab'
import FavoriteFavoriteTasksTab from './FavoriteTasksTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useFavorite } from '@/lib/db'
import { FavoriteRef } from '@/lib/types'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends FavoriteRef {
  tabId: string | undefined
}

export default function FavoritePage() {
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [favorite, loading] = useFavorite(params)

  if (loading) return <></>

  if (!favorite) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{favorite.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Devices" />
          <Tab label="Tasks" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <FavoriteInfoTab favorite={favorite} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <FavoriteFavoriteDevicesTab favorite={favorite} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <FavoriteFavoriteTasksTab favorite={favorite} />
        </TabPanel>
      </Paper>
    </>
  )
}
