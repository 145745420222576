import { Link, useLocation } from 'react-router-dom'
import { useFavoriteDevices } from '@/lib/db'
import { Favorite } from '@/lib/types'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function FavoriteFavoriteDevicesTab({ favorite }: Readonly<{ favorite: Favorite }>) {
  const [favoriteDevices, loading] = useFavoriteDevices(favorite)
  const location = useLocation()

  if (loading) return <></>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Favorite Device</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {favoriteDevices?.map((favoriteDevice) => {
            return (
              <TableRow key={favoriteDevice.id}>
                <TableCell>
                  <Link to={`${location.pathname}/devices/${favoriteDevice.id}`}>{favoriteDevice.name}</Link>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
