import { FavoriteTask } from '@/lib/types'
import { Table, TableBody, TableRow, TableCell } from '@mui/material'

export default function FavoriteTaskInfoTab({ favoriteTask }: Readonly<{ favoriteTask: FavoriteTask }>) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: '100%', whiteSpace: 'nowrap' }}>Name</TableCell>
          <TableCell sx={{ width: '100%' }}>{favoriteTask.name}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
