import { NavItemType } from 'types'
import { faUsers, faPenRuler, faWrench, faChartColumn, faPeopleCarryBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [
    {
      id: 'pages',
      title: 'Headquarters',
      icon: () => <FontAwesomeIcon icon={faUsers} />,
      type: 'group',
      children: [
        {
          id: 'accounts',
          title: 'Accounts',
          icon: () => <FontAwesomeIcon icon={faUsers} />,
          type: 'item',
          url: '/accounts',
        },
        {
          id: 'design-projects',
          title: 'Design Projects',
          icon: () => <FontAwesomeIcon icon={faPenRuler} />,
          type: 'item',
          url: '/design-projects',
        },
        {
          id: 'installation-projects',
          title: 'Installation Projects',
          icon: () => <FontAwesomeIcon icon={faWrench} />,
          type: 'item',
          url: '/installation-projects',
        },
        {
          id: 'installation-activity',
          title: 'Installation Activity',
          icon: () => <FontAwesomeIcon icon={faChartColumn} />,
          type: 'item',
          url: '/installation-activity',
        },
        {
          id: 'headquarters-users',
          title: 'Headquarters Users',
          icon: () => <FontAwesomeIcon icon={faPeopleCarryBox} />,
          type: 'item',
          url: '/headquarters-users',
        },
      ],
    },
  ],
}

export default menuItems
