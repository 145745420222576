import { useRouteMatch } from 'react-router-dom'
import OrgCustomersTab from './OrgCustomersTab'
import OrgMembersTab from './OrgMembersTab'
import OrgInfoTab from './OrgInfoTab'
import NotFoundPage from '@/components/NotFoundPage'
import { useOrg } from '@/lib/db'
import { useCurrentOrg } from '../auth/UserProvider'
import { OrgRef } from '@/lib/types'
import TabPanel from '@/ui-component/TabPanel'
import { Box, Typography, Paper, Tabs, Tab } from '@mui/material'
import { useState } from 'react'

interface MatchParams extends OrgRef {
  tabId: string | undefined
}

export default function OrgPage() {
  const currentOrg = useCurrentOrg()
  const { params } = useRouteMatch<MatchParams>()
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const [org, loading /*, error */] = useOrg({ ...params, orgId: currentOrg.id })

  if (loading) return <></>

  if (!org) return <NotFoundPage />

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box>
          <Typography variant="h6">{org.name}</Typography>
        </Box>
      </Box>
      <Paper>
        <Tabs value={tabValue} onChange={handleChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Info" />
          <Tab label="Customers" />
          <Tab label="Members" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <OrgInfoTab org={org} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <OrgCustomersTab org={org} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <OrgMembersTab org={org} />
        </TabPanel>
      </Paper>
    </>
  )
}
